@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

:root {
  --darkblue: #141c4d;
  --hintblue: #264e82;
  --green: #81992e;
  --green-dark: #414e32;
  --yellow: #987031;
  --yellow-bright: #f2a73b;
  --yellow-dark: #614c37;
  --grey: #8d837b;
  --orange: #e35205;
  --purple: #5c2650;
  --white: #ffffff;
  --blue: #102e69;
  --black: #000;

  --text-para: 12px;
  --text-button: 14px;
  --text-small: 12px;
  --text-medium: 14px;
  --text-large: 20px;

  --para-fs-small: 12px;
  --para-lh-small: 15px;

  --para-fs-standard: calc(12px * 5vh);
  --para-lh-standard: 18px;

  --title-fs-standard: 18px;
  --title-fs-large: 24px;

  --title-margin-h1: 10px;
  --title-margin-h2: calc(8px + 1vh);

  --content-gap: calc(100vh * 0.035);

  --lh-small: 14px;
  --lh-medium: 18px;

  --border-radius: 6px;

  --button-radius: 5px;

  --header-height: 50px;
  --header-padding: 12px;
  --footer-height: calc(10px + 2vh);
  --footer-height-extended: 97px;

  --margin: 20px;
  --margin-large: 12px;
  --margin-small: 12px;

  --family: "Montserrat", sans-serif;
  --fw-r: 400;
  --fw-m: 500;
  --fw-sb: 600;
  --fw-b: 700;

  --cw-tile-w: calc((100vw - 24px) / 19);
  --cw-tile-h: calc(((100vw - 24px) / 19) * 1.4);
  --cw-blank-h: calc(((100vw - 24px) / 19) * 0.5);
  --cw-margin: 0;
  --cw-width: 100%;

  --cw-text: 12px;

  --hover-opacity: 0.7;
  --transition-speed: 0.2s ease-in-out;
}

@media (orientation: portrait) {
  @media screen and (min-height: 668px) {
    :root {
      --para-fs-small: 12px;
      --para-lh-small: 15px;

      --para-lh-standard: 18px;

      --title-fs-standard: 22px;
      --title-fs-large: 30px;
      --title-margin-h1: 12px;

      --footer-height-extended: 120px;
    }
  }
}

@media screen and (min-height: 668px) {
  :root {
    --margin-large: 24px;

    --text-para: 16px;
    --text-button: 14px;
    --text-button: 14px;
    --text-small: 12px;
    --text-medium: 14px;
    --text-large: 24px;

    --lh-small: 16px;
    --lh-medium: 18px;

    --cw-text: 14px;

    --para-fs-small: 12px;
    --para-lh-small: 15px;

    --para-lh-standard: 18px;

    --title-fs-standard: 22px;
    --title-fs-large: 30px;

    --content-gap: calc(100vh * 0.04);
  }
}
@media screen and (min-height: 736px) {
  :root {
    --margin-large: 24px;

    --text-para: 16px;
    --text-button: 18px;
    --text-small: 14px;
    --text-medium: 18px;
    --text-large: 30px;

    --lh-small: 20px;
    --lh-medium: 24px;

    --content-gap: calc(100vh * 0.05);

    --button-radius: 5px;
  }
}
@media screen and (min-height: 1000px) {
  :root {
    --margin-large: 24px;

    --text-para: 16px;
    --text-button: 24px;
    --text-small: 14px;
    --text-medium: 22px;
    --text-large: 36px;

    --lh-small: 20px;
    --lh-medium: 30px;

    --content-gap: calc(100vh * 0.06);

    --button-radius: 5px;
  }
}

@media screen and (min-width: 360px) and (min-height: 668px) {
  :root {
    --header-height: 70px;
  }
}

@media screen and (min-width: 414px) and (min-height: 668px) {
  :root {
    --header-height: 80px;
  }
}

@media screen and (min-width: 576px) and (min-height: 814px) {
  :root {
    --header-height: 85px;
    --header-padding: 12px;
  }
}

@media screen and (min-width: 768px) and (min-height: 814px) {
  :root {
    --header-height: 90px;
    --header-padding: 18px;
  }
}
@media screen and (min-width: 992px) and (min-height: 912px) {
  :root {
    --header-height: 120px;
    --header-padding: 24px;
  }
}

@media screen and (min-width: 414px) and (min-height: 736px) {
  :root {
    --cw-margin: 0px;

    --cw-text: 14px;
  }
}

@media screen and (min-width: 414px) and (min-height: 736px) {
  :root {
    --cw-tile-w: 21px;
    --cw-tile-h: 32px;
    --cw-blank-h: 15px;
    --cw-margin: 0px;
    --cw-width: calc(
      (2 * var(--margin-small)) +
        (19 * ((2 * var(--cw-margin)) + var(--cw-tile-w)))
    );
    --cw-text: 14px;
  }
}

@media screen and (min-width: 576px) and (min-height: 812px) {
  :root {
    --cw-tile-w: 24px;
    --cw-tile-h: 36px;
    --cw-blank-h: 15px;
    --cw-margin: 0px;
    --cw-width: calc(
      (2 * var(--margin-small)) +
        (19 * ((2 * var(--cw-margin)) + var(--cw-tile-w)))
    );
    --cw-text: 14px;
  }
}

@media screen and (min-width: 768px) and (min-height: 812px) {
  :root {
    --cw-tile-w: 30px;
    --cw-tile-h: 45px;
    --cw-blank-h: 15px;
    --cw-margin: 0px;
    --cw-width: calc(
      (2 * var(--margin-small)) +
        (19 * ((2 * var(--cw-margin)) + var(--cw-tile-w)))
    );
    --cw-text: 16px;
  }
}
@media screen and (min-width: 992px) and (min-height: 812px) {
  :root {
    --cw-text: 19px;
  }
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--darkblue);
  width: 100%;
}

code {
  font-family: var(--family);
}

#outerContainer #mainContainer div.toolbar {
  display: none !important; /* hide PDF viewer toolbar */
}
#outerContainer #mainContainer #viewerContainer {
  top: 0 !important; /* move doc up into empty bar space */
}
