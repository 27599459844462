.single-row-container {
  display: flex;
  margin: 1px 0;

  @media screen and (min-height: 668px) {
    margin: 3px 0;
  }

  @media screen and (min-height: 736px) {
    margin: 3px 0;
  }
}
