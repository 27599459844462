.keyboard-key {
  width: 10%;
  max-width: 10%;
  height: 30px;
  border: 0.25px solid var(--white);
  color: var(--white);
  background-color: var(--darkblue);
  margin: 1px;
  border-radius: var(--button-radius);
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  flex: 1;
  padding: 0;

  &.clear,
  &.delete {
    width: 15%;
    max-width: 15%;
    padding: 0 5px;
    font-size: 10px;
  }

  &:active {
    background-color: var(--hintblue);
  }

  @media screen and (min-height: 668px) {
    height: 44px;
    font-size: 16px;
    line-height: 16px;
    flex: auto;
  }
  @media screen and (min-height: 812px) {
    border: 1px solid var(--white);
  }
}
