.symptom-button {
  opacity: 0;
  transition: 0.1s;
  box-sizing: border-box;
  overflow: hidden;
  background-size: 100%;
  margin: 2px;
  padding: 0;

  @media screen and (min-height: 668px) {
    margin: 5px;
  }

  &.clicked {
    opacity: 0.7;
    pointer-events: none;
  }

  &.active {
    opacity: 1;
  }

  &:active {
    transition: 0s;
    background-color: var(--hintblue);
  }

  &.correct {
    background-color: var(--green);
  }
  &.incorrect {
    background-color: var(--grey);
  }
  &.nearly {
    background-color: var(--yellow-bright);
  }

  img {
    width: 100%;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
