.single-tile-container {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  margin: 0 1px;
  border: 1px solid var(--white);
  border-radius: var(--button-radius);
  opacity: 0;
  transition: 0.3s;
  box-sizing: border-box;
  overflow: hidden;

  @media screen and (min-height: 668px) {
    width: 50px;
    height: 50px;
    margin: 0 3px;
  }

  @media screen and (min-height: 736px) {
    width: 70px;
    height: 70px;
    margin: 0 3px;
  }

  &.active {
    opacity: 1;
  }

  &.hightlight {
    background-color: var(--hintblue) !important;
  }
  &.added {
    background-color: rgba(255, 255, 255, 0.3);
  }
  &.correct {
    background-color: var(--green);
  }
  &.nearly {
    background-color: var(--yellow-bright);
  }
  &.incorrect {
    background-color: var(--grey);
  }

  img {
    width: 100%;
  }
}
