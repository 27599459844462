.patient-container {
  background-color: var(--orange);
  color: var(--white);
  border: none;
  width: 100%;

  position: relative;
  border-radius: var(--button-radius);
  height: 70px;

  .results {
    display: none;
  }

  &.complete {
    background-color: var(--darkblue);
    color: var(--grey);

    .results {
      display: block;
    }
  }

  h2 {
    margin: 0 0 5px;
  }
  p {
    margin: 0;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .details {
    margin: 0 0px;
    width: calc(100% - 100px);
  }

  .progress {
    position: absolute;
    left: 8px;
    top: 8px;
    &.hidden {
      opacity: 0.3;
    }
  }

  @media screen and (min-height: 668px) {
    height: 80px;

    .details {
      margin: 0 10px;
      width: calc(100% - 160px);
    }
  }
}
