.App {
  text-align: center;
  color: var(--white);
  background-color: var(--blue);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.screen {
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  position: relative;
  max-width: 420px;
  left: 50%;

  transform: translate3d(-50%, 0, 0);
  display: none;
  transition: 0.4s ease-in-out;
  opacity: 0;
  flex-direction: column;
  box-sizing: border-box;
  padding: var(--header-height) var(--margin-small);
  padding-bottom: var(--footer-height);
  justify-content: center;
  row-gap: var(--content-gap);

  @media screen and (min-height: 736px) {
    max-width: 500px;
  }

  &.visible {
    display: flex;
  }

  &.active {
    display: flex;
    opacity: 1;
  }

  .inner-section {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .button-holder {
    display: block;

    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

button {
  cursor: pointer;
  border: none;
  font-family: var(--family);
}

.link-fade {
  opacity: 1;
  transition: var(--transition-speed);

  @media (hover: hover) {
    &:hover {
      opacity: var(--hover-opacity);
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  margin: 0 0 var(--margin-large);
  font-size: var(--title-fs-large);
  line-height: var(--title-fs-large);
  font-weight: var(--fw-m);
}

h2 {
  margin: 0 0 var(--margin-small);
  font-size: var(--text-medium);
  line-height: var(--lh-medium);
  font-weight: var(--fw-m);
}

button {
  font-weight: var(--fw-sb);
}

.link-button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--white);
  text-decoration: underline;
  font-weight: var(--fw-r);
  font-size: var(--text-small);
}

.t-standard {
  margin: 0 0 var(--margin-large);
  font-size: var(--title-fs-standard);
  line-height: var(--title-fs-standard);
  font-weight: var(--fw-b);
}

.text-group {
  &.p-medium {
    p {
      font-size: var(--text-medium);
      line-height: var(--lh-medium);
      font-weight: var(--fw-m);
    }
  }
}

html,
body {
  h1 {
    margin: 0 0 var(--title-margin-h1);
    font-size: calc(14px + 2vh);
    line-height: calc(14px + 2vh);
  }

  h2 {
    margin: 0 0 var(--title-margin-h2);
    font-size: calc(12px + 1vh);
    line-height: calc(12px + 1vh);
  }

  p {
    font-size: calc(9px + 0.7vh);
    line-height: calc(13px + 0.7vh);

    &.p-light {
      font-size: var(--text-small);
      line-height: var(--lh-small);
      font-weight: var(--fw-r);
    }
    &.p-medium {
      font-size: var(--text-medium);
      line-height: var(--lh-medium);
      font-weight: var(--fw-m);
    }
  }

  button {
    &.standard-button {
      width: calc(60px + 30vh);
      height: calc(10px + 5vh);

      max-width: 310px;
      max-height: 65px;
      font-size: calc(12px + 1vh);
      background-color: var(--orange);
      border-radius: var(--button-radius);
      font-weight: 700;
      color: var(--white);
      border: 0;
      transition: var(--transition-speed);
      margin: 0 auto;

      @media (hover: hover) {
        &:hover {
          opacity: var(--hover-opacity);
        }
      }

      &.back-button {
        width: auto;
        height: calc(4px + 5vh);
        padding: 0 24px;
        font-weight: 600;
      }

      &.secondary-button {
        height: calc(10px + 4vh);
        width: calc(80px + 18vh);
        border-radius: var(--button-radius);
        font-size: calc(9px + 0.8vh);
        background-color: var(--hintblue);
        color: var(--white);
        margin: 0 auto;
        border: 0;
        font-weight: 600;
      }

      &.button-orange {
        background-color: var(--orange);
      }

      &.nav-button {
        &.hidden {
          display: none;
        }
      }

      // @media screen and (min-height: 667px) {
      //   width: 214px;
      //   height: 45px;

      //   &.back-button {
      //     height: 40px;
      //     width: auto;
      //   }
      // }

      // @media screen and (min-height: 738px) {
      //   width: 261px;
      //   height: 55px;

      //   &.back-button {
      //     height: 45px;
      //     width: auto;
      //   }
      // }

      // @media screen and (min-height: 1000px) {
      //   width: 309px;
      //   height: 65px;
      // }
    }
  }
}
