.meet-the-syms {
  &.screen {
    justify-content: space-evenly;
    height: 100%;
    padding-bottom: calc(var(--footer-height-extended));

    row-gap: 0;

    .text-holder {
      width: 100%;
      max-width: 500px;
      padding: 20px;
      box-sizing: border-box;
      z-index: 1;
    }

    .syms-carousel {
      width: 100%;
      position: relative;
      height: auto;

      .name {
        font-size: var(--text-medium);
        font-weight: bold;
        margin-bottom: 0px;
      }
      sup {
        vertical-align: super;
        font-size: 12px;

        @media screen and (min-height: 668px) {
          font-size: 14px;
        }
      }

      .percent {
        font-size: 24px;
        line-height: 24px;

        font-weight: bold;
        margin: 0;

        @media screen and (min-height: 668px) {
          font-size: calc(15px + 6vh);
          line-height: calc(15px + 6vh);
        }
      }

      .occurance {
        font-size: var(--text-para);
        margin: 0;
      }

      .container {
        width: 100%;
        height: 117px;
        position: relative;
        margin: 5px 0;
        @media screen and (min-height: 668px) {
          margin: 10px 0;
        }
      }
      .inner {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        width: 600px;
        height: 100%;

        @media screen and (min-height: 800px) {
          width: 700px;
        }

        @media screen and (min-height: 900px) {
          width: 900px;
        }
      }

      .left-button,
      .right-button {
        z-index: 999;
        position: absolute;
        top: 50%;
        width: 34px;
        height: 34px;
        border: none;
        background-color: transparent;
        background-image: url(../../assets/imgs/carousel_button.png);
        transform: translateY(-50%);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transition: 0.2s;

        @media screen and (min-height: 736px) {
          width: 64px;
          height: 64px;
        }
      }
      .right-button {
        transform: translateY(-50%) scaleX(-1);
      }
      @media (hover: hover) {
        .left-button:hover {
          opacity: 0.9;
          transform: translateY(-50%) scale(1.05);
        }
      }
      .left-button:active {
        opacity: 0.7;
        transform: translateY(-50%) scale(0.95);
        transition: 0s;
      }
      @media (hover: hover) {
        .right-button:hover {
          opacity: 0.9;
          transform: translateY(-50%) scale3d(-1.05, 1.05, 1.05);
        }
      }

      .right-button:active {
        opacity: 0.7;
        transform: translateY(-50%) scale3d(-0.95, 0.95, 0.95);
        transition: 0s;
      }

      .left-button {
        left: 0;
      }
      .right-button {
        right: 0;
      }

      .entry {
        position: absolute;
        width: 30px;
        height: 50px;
        background-color: var(--white);
        opacity: 0;
        transition: 0s;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        border-radius: 4px;
        overflow: hidden;

        img {
          width: 100%;
        }

        &.primary {
          transition: left 0.2s ease-in-out,
            width 0.3s cubic-bezier(0.34, 1.56, 0.64, 1),
            height 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
          opacity: 1;
          width: 70px;
          height: 117px;
        }
        &.secondary {
          transition: 0.2s ease-in-out;
          opacity: 0.7;
          width: 50px;
          height: 83px;
        }
        &.tertiary {
          transition: 0.2s ease-in-out;
          // opacity: 0;
        }
      }

      @media screen and (min-height: 736px) {
        .container {
          height: 150px;
        }
        .entry {
          width: 30px;
          height: 50px;

          &.primary {
            width: 90px;
            height: 150px;
          }
          &.secondary {
            width: 50px;
            height: 83px;
          }
        }
      }
      @media screen and (min-height: 900px) {
        .container {
          height: 200px;
        }
        .entry {
          width: 50px;
          height: 83px;

          &.primary {
            width: 120px;
            height: 200px;
          }
          &.secondary {
            width: 70px;
            height: 117px;
          }
        }
      }
    }

    .pip-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 6px 0 0;

      .pip {
        width: 12px;
        height: 12px;
        background-color: var(--white);
        border-radius: 20px;
        margin: 5px;

        &.active {
          background-color: var(--orange);
        }
      }
    }
  }
}
