.load-screen-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  display: none;
  z-index: 999;

  transition: 0.6s;

  &.active {
    display: block;
    opacity: 1;
  }
  &.fade {
    opacity: 0;
  }

  .inner {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 250px;

    .lineup {
      width: 100%;

      img {
        width: 100%;
      }
      margin-bottom: 20px;
    }

    .bar {
      width: 100%;
      height: 15px;
      position: relative;

      .bar-bg {
        background-color: var(--orange);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .bar-top {
        background-color: var(--yellow-bright);
        width: 0%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
      }
    }
  }
  // .lineup {
  //   position: absolute;
  //   top: -140px;
  //   margin-bottom: var(--margin);
  // }

  // .lockup {
  //   margin-bottom: var(--margin);
  // }

  // .loader {
  //   position: absolute;
  //   top: 80px;
  //   width: 100%;
  //   height: 20px;
  //   background-color: var(--yellow);
  // }

  // .loadbar {
  //   width: 0%;
  //   background-color: var(--orange);
  //   transition: 0.1s;
  //   height: 100%;
  // }

  // img {
  //   width: 100%;
  // }
}
