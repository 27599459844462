.crossword-intro {
  &.screen {
    row-gap: 0;
    justify-content: space-evenly;
  }
  .image-container {
    img {
      width: 80%;
    }
  }
}
