.submit-button {
  opacity: 0.2;
  margin-left: 20px;

  background-color: transparent;
  width: 45px;
  height: 45px;
  padding: 0;
  img {
    width: 100%;
  }

  &.active {
    opacity: 1 !important;
  }

  &:active {
    transition: 0s;
    opacity: 0.5;
    transform: scale(0.95);
  }
}
