.patient-screen-container {
  &.screen {
    justify-content: space-evenly;
  }

  .reset-button {
    display: block;
    padding: 10px;
    margin: 0 auto;
    width: auto;
  }

  .text-holder {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0;
    box-sizing: border-box;
    z-index: 1;
    justify-content: space-evenly;
    height: 100%;
  }

  .image-container {
    display: none;
    img {
      width: 80%;
    }

    @media screen and (min-height: 668px) {
      display: block;
    }
  }

  .standard-button {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 150%, 0);
  }
}
