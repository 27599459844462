.crossword-row {
  display: flex;
  margin: 1px 0;

  &.cw-spacing-1 {
    margin-left: calc(var(--cw-tile-w));
  }
  &.cw-spacing-2 {
    margin-left: calc(2 * (var(--cw-tile-w)));
  }
  &.cw-spacing-3 {
    margin-left: calc(3 * (var(--cw-tile-w)));
  }
  &.cw-spacing-4 {
    margin-left: calc(4 * (var(--cw-tile-w)));
  }
  &.cw-spacing-5 {
    margin-left: calc(5 * (var(--cw-tile-w)));
  }
  &.cw-spacing-6 {
    margin-left: calc(6 * (var(--cw-tile-w)));
  }
  &.cw-spacing-7 {
    margin-left: calc(7 * (var(--cw-tile-w)));
  }
  &.cw-spacing-8 {
    margin-left: calc(8 * (var(--cw-tile-w)));
  }
  &.cw-spacing-9 {
    margin-left: calc(9 * (var(--cw-tile-w)));
  }

  @media (max-width: 1399.98px) {
  }

  @media (max-width: 1199.98px) {
  }

  @media (max-width: 991.98px) {
  }

  @media (max-width: 767.98px) {
  }

  @media (max-width: 575.98px) {
  }

  @media (max-width: 389.99px) {
  }

  @media (hover: hover) {
  }
}
