.symptoms-container {
  // display: grid;
  // grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  width: auto;
  height: auto;
  // position: absolute;
  // left: 50%;
  // bottom: 0;
  // transform: translateX(-50%);
  margin: 0;
  // justify-content: space-evenly;
  // justify-items: center;
  // align-content: space-evenly;
  // align-items: center;

  @media screen and (min-height: 668px) {
    column-gap: 10px;
    row-gap: 10px;
  }

  .top,
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .action-button {
    min-width: 120px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &:active {
      opacity: 0.6;
    }
  }

  .keyboard-button {
    width: 39px;
    height: 65px;
    border-radius: var(--button-radius);
    outline: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    border: none;

    img {
      object-fit: cover;
      height: 100%;
    }

    @media screen and (min-height: 736px) {
      width: 48px;
      height: 80px;
    }
  }
}
