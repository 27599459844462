.intro-screen-container {
  &.screen {
    row-gap: 0;
    justify-content: space-evenly;
  }

  .text-holder {
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    z-index: 1;
  }
  .image-container {
    img {
      width: 80%;
    }
  }
}
