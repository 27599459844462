header {
  position: absolute;
  width: 100%;
  background-color: var(--white);
  top: 0%;
  transition: 0.6s ease-in-out;
  z-index: 4;

  height: var(--header-height);

  &.intro {
    .lineup-img {
      margin-bottom: 6px;
    }
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .panel {
    position: fixed;
    z-index: 1;
    width: auto;
    top: 0;
    right: 0;
    height: 100%;
    transform: translateX(100%);
    transition: 0.4s ease-in-out;
    background-color: var(--white);
    text-align: center;
    padding: calc(var(--header-height) + 12px) 12px 12px;
    box-sizing: border-box;
    &.active {
      transform: translateX(0%);
    }

    @media screen and (min-width: 576px) {
      padding: calc(var(--header-height) + var(--margin-large))
        var(--margin-large) var(--margin-large);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      button {
        margin: 0 auto 20px;
      }
    }
  }

  .burger {
    position: absolute;
    right: 12px;
    height: 34px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    width: 34px;
    background-color: var(--orange);
    border-radius: 4px;
    padding: 0;
    transition: 0.2s ease-in-out;
    display: none;

    @media screen and (min-height: 736px) and (min-width: 576px) {
      height: 48px;
      width: 48px;
      right: 12px;
    }

    @media screen and (min-height: 812px) and (min-width: 576px) {
      right: 24px;
    }

    &.visible {
      display: block;
    }

    .line {
      width: 16px;
      height: 1.5px;
      position: absolute;
      background-color: var(--white);
      transform-origin: center;
      left: 50%;
      top: 50%;
      transition: 0.2s ease-in;

      &.line-1 {
        transform: translate3d(-50%, -7px, 0);
      }

      &.line-2 {
        transform: translate3d(-50%, -1px, 0);
      }

      &.line-3 {
        transform: translate3d(-50%, 5px, 0);
      }

      @media screen and (min-height: 736px) and (min-width: 576px) {
        height: 2.5px;
        width: 24px;

        &.line-1 {
          transform: translate3d(-50%, -9px, 0);
        }

        &.line-2 {
          transform: translate3d(-50%, -1px, 0);
        }

        &.line-3 {
          transform: translate3d(-50%, 7px, 0);
        }
      }
    }

    &:active {
      background-color: var(--grey);
    }

    @media (hover: hover) {
      &:hover,
      &:active {
        opacity: var(--hover-opacity);

        .line {
          transition: 0.2s ease-in-out;

          &.line-1 {
            transform: translate3d(-50%, -6px, 0);
          }

          &.line-2 {
            opacity: 0;
          }

          &.line-3 {
            transform: translate3d(-50%, 5px, 0);
          }
        }
      }
    }

    &.active {
      background-color: var(--orange);

      .line {
        transition: 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);

        &.line-1 {
          transform: translate3d(-50%, -1px, 0) rotateZ(45deg);
        }

        &.line-2 {
          opacity: 0;
        }

        &.line-3 {
          transform: translate3d(-50%, -1px, 0) rotateZ(-45deg);
        }
      }
    }
  }

  .content {
    width: auto;
    height: auto;
    position: absolute;
    width: calc(70%);

    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    transition: 0.6s ease-in-out;

    box-sizing: border-box;
    height: 100%;

    max-height: 100%;

    &.loaded {
      transform: translate3d(-50%, -85%, 0);
    }

    .lockup-container {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 6px var(--margin-small);
      justify-content: center;
      box-sizing: border-box;

      @media screen and (min-height: 668px) {
        padding: var(--margin-small);
      }

      // @media screen and (min-height: 668px) and (min-width: 376px) {
      //   width: 280px;
      // }

      // @media screen and (min-height: 736px) and (min-width: 415px) {
      //   width: 480px;
      // }

      .lockup {
        position: relative;
        overflow: hidden;
        height: auto;
        display: flex;
        align-items: center;
        transition: 0.2s;
        justify-content: center;

        img {
          width: auto;
          height: auto;
          position: relative;
          left: 0;
          bottom: 0;
          max-height: 100%;
          max-width: 500px;

          @media screen and (max-width: 768px) {
            max-width: 100%;
          }
        }

        &.lockup-lineup {
          height: 0;
          &.active {
            height: auto;
          }
        }
        &.lockup-crossword {
          height: 0;

          &.active {
            height: auto;
          }
        }
        &.lockup-symptle {
          height: 0;
          &.active {
            height: auto;
          }
        }
      }
    }

    .lineup {
      position: relative;
      margin-bottom: var(--margin-small);
      left: 0;
      width: auto;
    }

    // .lockup {
    //   position: relative;
    //   height: auto;
    //   left: 0;
    //   bottom: 0;
    //   display: none;
    //   max-height: 100%;

    //   &.lockup-lineup {
    //     height: 100%;
    //   }

    //   &.active {
    //     display: block;
    //   }

    .loader {
      display: none;
      position: absolute;
      left: 0;
      bottom: -38px;
      width: 100%;
      height: 20px;
      background-color: var(--yellow);

      &.loaded {
        display: none;
      }
    }

    .loadbar {
      width: 0%;
      background-color: var(--orange);

      height: 100%;
    }
  }

  .back {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    height: 40px;
  }

  .nav {
    position: absolute;
    right: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      height: 40px;
      margin-left: 10px;
    }

    .home-button {
      &.inactive {
        pointer-events: none;
        opacity: 0.5;
      }
    }
    .syms-button {
      &.inactive {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
