.win-screen-container {
  .tile-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .tile {
      width: calc(100% * 1 / 11);
      height: calc(100vw * (1 / 11) * (140 / 90));
      max-height: calc(420px * (1 / 11) * (140 / 90));
      border: 1px solid var(--white);
      background-color: var(--green);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      border-radius: var(--button-radius);

      &.margin {
        margin-right: var(--margin-small);
      }

      @media screen and (min-height: 736px) {
        font-size: 28px;
        max-height: calc(500px * (1 / 11) * (140 / 90));
      }
    }
  }

  .image-container {
    img {
      width: 100%;
    }

    @media screen and (min-height: 668px) {
      display: block;
    }
  }
}
