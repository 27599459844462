.game-board-container {
  &.screen {
    row-gap: 0;
    justify-content: space-evenly;
  }

  .win-message {
    display: none;
    width: 100%;
    position: absolute;
    top: 120px;

    p {
      font-size: 24px;
    }

    &.active {
      display: block;
    }
  }

  .text-holder {
    width: 100%;
    max-width: 500px;
    background-color: aqua;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1;
  }

  .complete {
    width: 100%;
    text-align: center;
  }
}
