.question-pannel {
  display: flex;
  flex-direction: row;
  max-width: 500px;
  width: 100%;
  margin: 0 auto 0;
  justify-content: space-between;
  align-items: center;
  height: 42px;

  @media screen and (min-height: 736px) {
    height: 54px;
  }

  .inner {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 2px 5px;
    background-color: var(--hintblue);
    align-items: center;
    text-align: left;
    height: 100%;
    box-sizing: border-box;

    @media screen and (min-width: 376px) {
      padding: 2px var(--margin-small);
    }

    img {
      width: 30px;
      height: 100%;
      object-fit: cover;
      background-color: var(--white);
      border-radius: var(--border-radius);

      @media screen and (min-width: 376px) {
        width: 34px;
      }

      @media screen and (min-height: 736px) {
        width: 48px;
      }
    }

    .queston {
      margin-left: 6px;
      font-size: var(--cw-text);
      display: flex;

      @media screen and (min-width: 376px) {
        margin-left: var(--margin-small);
      }

      .q-number {
        margin-right: 5px;
      }
    }
  }

  .cycle-button {
    position: relative;
    width: 40px;
    height: 40px;

    border: none;
    background-color: transparent;
    background-image: url(../../../assets/imgs/carousel_button.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: 0.2s;

    @media screen and (min-height: 736px) {
      width: 52px;
      height: 52px;
    }

    &.right {
      transform: scaleX(-1);
    }
  }
}
