.crossword-game {
  &.screen {
    width: var(--cw-width);
    max-width: var(--cw-width);
    row-gap: 0;
    justify-content: space-evenly;
  }
  position: relative;
  width: 100%;
  padding: 80px 0;
  height: 100%;
  display: none;
  transition: 0.4s ease-in-out;
  opacity: 0;
  box-sizing: border-box;
  text-align: center;

  &.visible {
    display: block;
  }

  &.active {
    display: block;

    opacity: 1;
  }

  .text-holder {
    width: 100%;
    max-width: 500px;
    background-color: aqua;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1;
    border-radius: 4px;
    margin: 0 auto 60px;
  }

  input {
    margin: 0 auto 0px;
    height: 40px;
    line-height: 40px;
    width: 300px;
    opacity: 0;
    position: absolute;
    bottom: 0;
    transform: translateY(-1000px);
  }
}
