.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  color: var(--white);
  z-index: 3;

  .pi {
    position: relative;
    height: var(--footer-height);
    line-height: var(--footer-height);

    .content {
      display: flex;
      column-gap: 24px;
      justify-content: center;
    }
  }

  .references {
    position: relative;
  }

  .content {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    font-size: var(--para-fs-small);

    .content-inner {
      max-width: 600px;
      margin: 0 auto;
      padding: 0 var(--margin-small);
    }

    &.ref {
      transition: 0.3s ease-in-out;
      position: absolute;
      transform: translateY(100%);
      bottom: 0;
      overflow: hidden;
      background-color: var(--hintblue);
      padding-bottom: var(--footer-height);
      &.active {
        transform: translateY(0);
      }

      .nb {
        font-size: 10px;
        text-align: left;
        line-height: 12px;

        @media screen and (min-height: 668px) {
          font-size: 12px;
          text-align: left;
          line-height: 14px;
        }

        @media screen and (min-height: 812px) {
          font-size: 14px;
          text-align: left;
          line-height: 17px;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 2px;
        font-size: 10px;
        text-align: left;
        line-height: 12px;

        @media screen and (min-height: 668px) {
          font-size: 12px;
          text-align: left;
          line-height: 14px;
        }

        @media screen and (min-height: 812px) {
          font-size: 14px;
          text-align: left;
          line-height: 17px;
        }
      }
    }

    .top-section {
      display: flex;

      align-items: flex-start;
      .button-box {
        width: 40px;
        margin-right: 20px;
        margin-top: 12px;
        button {
          position: relative;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          border: none;

          background-color: var(--orange);
          border-radius: 8px;
          background-image: url(../../assets/imgs/icon_ref.png);
          background-size: 60%;
          background-position: center;
          background-repeat: no-repeat;

          &.active {
            background-image: url(../../assets/imgs/icon_close.png);
          }
        }
      }
    }
    .bottom-section {
      overflow: hidden;
      max-height: 0;
      transition: 0.3s ease-in-out;

      .ref-title {
        margin-bottom: 0;
        font-weight: bold;
      }

      .ref-number {
        font-weight: bold;
        margin-right: 5px;
      }

      &.active {
        max-height: 200px;
      }
    }
  }
}
