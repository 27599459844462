.crossword-row-container {
  position: relative;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  // left: 50%;
  // top: 50%;
  // transform: translate3d(-50%, -50%, 0);

  .outer {
    width: 100%;

    .blank-row {
      margin-left: calc(10 * var(--cw-tile-w));
    }
  }

  .blank-tile {
    background-color: var(--grey);
    width: var(--cw-tile-w);
    height: var(--cw-tile-h);
    border-radius: 4px;
    text-align: center;
    font-size: var(--cw-text);
    line-height: var(--cw-tile-h);
    margin: 2px 0px 1px;
    box-sizing: border-box;

    &.blank-- {
      width: var(--cw-tile-w);
      height: var(--cw-blank-h);
      line-height: var(--cw-blank-h);
      border: 0.5px solid var(--white);
      border-radius: 3px;
    }
    &.blank-question {
      width: var(--cw-tile-w);
      height: var(--cw-tile-h);
      line-height: var(--cw-tile-h);
      border: 0.5px solid var(--white);
      background-color: var(--green);
    }
  }
}
