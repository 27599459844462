.patient-progress-row {
  display: flex;
  flex-direction: row;

  .tile {
    width: 6px;
    height: 6px;
    margin: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 0.5px solid #fff;
    border-radius: 3px;

    &.correct {
      background-color: var(--green);
    }
    &.nearly {
      background-color: var(--yellow-bright);
    }
    &.incorrect {
      background-color: grey;
    }
  }

  @media screen and (min-height: 668px) {
    .tile {
      width: 8px;
      height: 8px;
    }
  }
}
