.win-screen-container {
  &.screen {
    row-gap: 0;
    justify-content: space-evenly;
    padding-bottom: 40px;
  }

  .patient-title {
    width: 100%;
    min-height: none;
    background-color: var(--green);
    border-radius: var(--button-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    padding: 8px 16px;
    box-sizing: border-box;

    @media screen and (min-height: 736px) {
      padding: 8px 24px;
      min-height: 60px;
    }

    h2 {
      margin: 0 0;
    }
    p {
      margin: 0;
    }
  }

  .button-holder {
    p {
      margin: 6px 0;
    }
  }

  .symptoms {
    display: flex;
    flex-direction: row;

    p {
      margin: 6px 0 0;
    }

    &.symptoms-large {
      display: none;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 auto;
      grid-row-gap: 20px;
      width: 100%;
    }

    &.symptoms-small {
      p {
        display: none;
      }
    }

    @media screen and (min-height: 736px) {
      &.symptoms-small {
        display: none;
      }
      &.symptoms-large {
        display: flex;
      }
    }

    .symptom {
      width: 50%;
      box-sizing: border-box;
      padding: 0 6px;
      box-sizing: border-box;
      text-align: center;

      img {
        border-radius: 8px;
        width: 100%;
        max-width: 70%;
        background-color: var(--white);
      }
    }

    @media screen and (min-height: 736px) {
      .symptom {
        img {
          max-width: 42%;
        }
      }
    }
  }
}
