.how-to-play {
  &.screen {
    .text-holder {
      width: 100%;
      max-width: 500px;
      background-color: aqua;
      padding: 20px;
      box-sizing: border-box;
      z-index: 1;
    }
    row-gap: unset;
    justify-content: space-evenly;
    height: calc(100%);

    @media screen and (min-height: 668px) {
      row-gap: 24px;
    }
    @media screen and (min-height: 737px) {
      justify-content: center;
      row-gap: 36px;
    }

    .text-group {
      p {
        margin: 0 0 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .nb {
      font-size: 10px;
      text-align: left;
      line-height: 12px;

      @media screen and (min-height: 668px) {
        font-size: 12px;
        text-align: left;
        line-height: 14px;
      }
    }

    .cycle-holder {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      justify-content: center;

      .cycle-button {
        width: 32px;
        height: 32px;
        border: none;
        background-color: transparent;
        background-image: url(../../assets/imgs/carousel_button.png);

        background-size: 100% 100%;
        background-repeat: no-repeat;

        &.right {
          transform: scaleX(-1);
        }

        @media screen and (min-height: 736px) {
          width: 48px;
          height: 48px;
          column-gap: 48px;
        }
      }
    }

    .hint-container {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      margin: 0 auto;
      max-width: 300px;

      @media screen and (min-height: 736px) {
        max-width: 300px;
      }

      .hint-row-crossword {
        display: flex;
        flex-direction: row;
        text-align: left;
        column-gap: 12px;
        align-items: center;

        p {
          margin: 0;
        }

        .hint-block-crossword {
          width: 32px;
          min-width: 32px;
          height: 48px;
          border: 1px solid #fff;
          border-radius: var(--border-radius);

          &.yellow {
            background-color: var(--yellow);
          }
          &.green {
            background-color: var(--green-dark);
          }
          &.red {
            background-color: var(--purple);
          }
        }
      }
    }

    .hint-container-symptle {
      .hint-row {
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: center;
        margin-top: var(--margin-small);

        &:first-child {
          margin-top: 0;
        }

        .hint-box {
          width: 36px;
          height: 36px;

          @media screen and (min-height: 812px) {
            width: 48px;
            height: 48px;
          }

          border: 1px solid #fff;
          border-radius: var(--button-radius);

          &.green {
            background-color: var(--green);
          }
          &.yellow {
            background-color: var(--yellow-bright);
          }
          &.grey {
            background-color: var(--grey);
          }
        }
      }
      &.hint-symptle-small {
        margin: 0 auto;
        max-width: 300px;
        .hint-box {
          width: 36px;
          height: 36px;
          min-width: 36px;
        }
        p {
          text-align: left;

          margin: 0;
        }
        .hint-row {
          align-items: center;
          justify-content: flex-start;
        }
      }

      &.hint-symptle-large {
        display: none;
        p {
          margin: 5px 0 10px;
        }
      }

      @media screen and (min-height: 736px) {
        &.hint-symptle-large {
          display: block;
        }
        &.hint-symptle-small {
          display: none;
        }
      }
    }
  }
}
