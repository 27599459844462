.crossword-tile {
  width: var(--cw-tile-w);
  height: var(--cw-tile-h);
  text-align: center;

  margin: 0 var(--cw-margin);
  border: 0.25px solid var(--white);
  border-radius: var(--button-radius);
  opacity: 0;
  transition: 0.3s;
  box-sizing: border-box;
  overflow: hidden;
  line-height: var(--cw-tile-h);
  font-size: var(--cw-text);
  background-color: var(--darkblue);
  font-weight: 500;
  position: relative;

  &.active {
    opacity: 1;
  }

  &.added {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &.incorrect {
    background-color: var(--purple);
    &.hightlight {
      background-color: var(--yellow);
    }
  }
  &.nearly {
    background-color: var(--yellow-dark);
    &.hightlight {
      background-color: var(--yellow);
    }
  }
  &.complete {
    background-color: var(--green-dark) !important;
  }

  &.solid {
    background-color: var(--green);
  }
  &.nearly.solid {
    background-color: var(--green);
    &.hightlight {
      background-color: #b7ce66 !important;
    }
  }
  &.solid {
  }

  img {
    width: 100%;
  }

  .clue-number {
    display: none;
    font-size: 10px;
    position: absolute;
    left: 2px;
    top: 2px;
    color: #fff;
    line-height: 10px;
  }

  &.tile-order-0 {
    @media screen and (min-height: 812px) {
      .clue-number {
        display: block;
      }
    }
  }
  @media screen and (min-height: 812px) {
    border: 1px solid var(--white);
  }
}
