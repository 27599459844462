.landing {
  width: 100%;
  height: 100%;

  .screen {
    justify-content: space-evenly;
  }

  .screen-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: var(--content-gap);
  }

  .resources {
    h2 {
      margin-top: 0;
    }

    ul {
      list-style: none;
      text-align: center;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .alexion {
    max-width: 175px;
  }

  .adverse {
    padding: 20px;
    background-color: var(--hintblue);
  }
}
